import React, { useState } from 'react'
import './Header.css'
import { Link } from "react-scroll";
import Testimonials from '../Testimonials/Testimonials';
const Header = () => {


  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false)
  return (
    <div className="header">
      <img src="/img/Logo.png" alt="" className='logo' />
      {(menuOpened === false && mobile === true) ? (

        <div
          style={{ backgroundColor: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px' }}


          onClick={() => setMenuOpened(true)}

        ><img src="/img/bars.png" alt="" style={{ width: '1.5rem', height: '1.5rem' }} /></div>
      ) : <ul className='header-menu'>
        <li>

          <Link
            onClick={() => setMenuOpened(false)}
            activeClass='active'
            to='Home'
            spy={true}
            smooth={true}

          >Home</Link>

        </li>
        <li>


          <Link
            onClick={() => setMenuOpened(false)}

            to='Programs'
            spy={true}
            smooth={true}>Programs
          </Link>
        </li>
        <li >
          <Link
            onClick={() => setMenuOpened(false)}
            to='Reasons'
            spy={true}
            smooth={true}

          > Why us

          </Link>

        </li>
        <li >
          <Link
            onClick={() => setMenuOpened(false)}
            to='plans'
            spy={true}
            smooth={true}
          >plans
          </Link>
        </li>
        <li > <Link
          onClick={() => setMenuOpened(false)}
          to='Testimonials'
          spy={true}
          smooth={true}

        >Testimonials</Link></li>
      </ul>
      }



    </div>
  )
}

export default Header
