import React from 'react'
import Header from './Header/Header'
import './Hero.css'
import {motion} from 'framer-motion'

const Hero = () => {

    const transition = {type: 'spring', duration: 3}
    const mobile = window.innerWidth<=768 ? true: false;
    return (
        <div className="hero"  id='Home'>
            <div className="blur hero-blur"></div>
            <div className="left-h">

                <Header />


                <div className="the-best-ad">
                    <motion.div
                    initial={{left: mobile? '165px': '238px'}}
                    whileInView={{left: '8px'}}
                    transition={{...transition, type:'tween'}}


                    
                    ></motion.div>
                    <span>The Best fitness club in the Town</span>
                </div>

                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape</span>
                        <span> Your</span>
                    </div>
                    <div>
                        <span>Ideal </span>
                        <span>Body</span>
                    </div>
                    <div>
                        <span>

                            IN HERE WE WILL HELP YOU TO SHAPE AND BUILD YOUR BODY AND LIVE UP YOUR LIFE THE FULLEST
                        </span>
                    </div>
                </div>
                {/* figures */}
                <div className="figures">
                    <div><span>+148</span>
                    <span>expert coaches</span>
                    </div>
                    <div><span>+978</span>
                    <span>members joined</span></div>
                    <div><span>+50</span>
                    <span>fitness programs</span></div>
                </div>

                <div className="hero-buttons">
                    <buttons className="btn">Get Started</buttons>
                    <buttons className="btn">Learn More</buttons>
                </div>

            </div>
            <div className="right-h">
                <button className='btn'>Join Now</button>


                <motion.div 

                initial={{right: '-1rem'}}
                 whileInView={{right: '4rem'}}
                 transition={transition}
                
                className="heart-rate">
                    <img src="/img/heart.png" alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>
                <img src="/img/hero.png" alt="" className='hero-image' />
                <motion.img 
                initial={{right: '11rem'}}
                whileInView={{right: '20rem'}}
                transition={transition}
                
                src="/img/hero_image_back.png" alt="" className='hero_image_back' />

                <motion.div 
                 initial={{right: '37rem'}}
                 whileInView={{right: '28rem'}}
                 transition={transition}
                
                
                
                className="calories">
                    <img src="/img/calories.png" alt="" />
                    <div>
                    <span>CaloriesBurned</span>
                    <span>220kcal</span>
                        
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default Hero
